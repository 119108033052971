<template>
    <div class="frame-content">
        <div class="frame-content-wrap">
            <div class="datatable-wrap">
                <div class="topbar">
                    <div class="inline">
                        <el-button type="primary" size="small" @click="handleAdd">新增</el-button>
                    </div>
                    <div class="inline ml-8">
                        <el-button type="danger" size="small" @click="handleDeleteAll">删除</el-button>
                    </div>
                    <div class="inline ml-8">
                        <el-button type="success" size="small" @click="handleDisable(0)">启用</el-button>
                    </div>
                    <div class="inline ml-8">
                        <el-button type="warning" size="small" @click="handleDisable(1)">禁用</el-button>
                    </div>
                </div>
                <div class="datatable">
                    <el-table
                        ref="currentTable"
                        :data="tableData"
                        v-loading="tableLoading"
                        element-loading-background="rgba(255, 255, 255, 0.3)"
                        border
                        style="width: 100%">
                        <el-table-column
                            type="selection"
                            width="55"
                            align="center">
                        </el-table-column>
                        <el-table-column
                            label="序号"
                            align="center"
                            width="80">
                            <template slot-scope="scope">
                                <span>{{ (pageNum - 1) * pageSize + scope.$index + 1}}</span>
                            </template>
                        </el-table-column>
                        <el-table-column
                            prop="name"
                            label="角色名称">
                        </el-table-column>
                        <el-table-column
                            prop="enabled"
                            label="状态"
                            align="center">
                            <template slot-scope="scope">
                                <el-tag v-if="scope.row.enabled == 0" type="primary" disable-transitions>启用</el-tag>
                                <el-tag v-else type="danger" disable-transitions>禁用</el-tag>
                            </template>
                        </el-table-column>
                        <el-table-column
                            prop="created"
                            label="创建时间"
                            align="center"
                            width="180">
                        </el-table-column>
                        <el-table-column
                            prop="action"
                            label="操作"
                            align="center"
                            width="120">
                            <template slot-scope="scope">
                                <el-button
                                    size="mini"
                                    type="warning"
                                    @click="handleEdit(scope.$index, scope.row)">修改</el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
                <div class="pagination">
                    <el-pagination
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange"
                        :background="true"
                        :current-page="pageNum"
                        :page-sizes="[50, 100, 200, 300, 400, 500]"
                        :page-size="pageSize"
                        layout="total, sizes, prev, pager, next, jumper"
                        :total="totalNum">
                    </el-pagination>
                </div>
            </div>

            <el-dialog
                title="添加"
                v-if="addDialog.create"
                :visible.sync="addDialog.visible"
                :close-on-click-modal="false"
                @closed="handleAddClosed"
                class="custom-dialog mini-dialog"><!-- big-dialog small-dialog mini-dialog  -->
                <RoleAdd @done="handleAddDone"></RoleAdd>
            </el-dialog>
            
            <el-dialog
                title="修改"
                v-if="editDialog.create"
                :visible.sync="editDialog.visible"
                :close-on-click-modal="false"
                @closed="handleEditClosed"
                class="custom-dialog mini-dialog"><!-- big-dialog small-dialog mini-dialog  -->
                <RoleEdit :params="editDialog.params" @done="handleEditDone"></RoleEdit>
            </el-dialog>
        </div>
    </div>
</template>

<script>
import common from '@/utils/common'
import http from '@/utils/http'
import RoleAdd from './RoleAdd'
import RoleEdit from './RoleEdit'

export default {
    name: 'SystemRole',
    components: {
        RoleAdd,
        RoleEdit
    },
    data() {
        return {
            filters: {
                roleName: '',
            },

            tableData: [],

            totalNum: 0,
            pageNum: 1,
            pageSize: 50,

            tableLoading: false,

            addDialog: {
                create: false,
                visible: false,
            },

            editDialog: {
                create: false,
                visible: false,
                params: {},
            },
        };
    },
    created () {
        this.loadTable();
    },
    methods: {
        ...common,
        // 加载表格数据
        loadTable() {
            let params = {...this.filters};

            params.pageNum = this.pageNum;
            params.pageSize = this.pageSize;

            this.tableLoading = true;
            http.get('v1/role/listPage', {
                params: params
            }).then(req => {
                this.tableLoading = false;
                if (this.httpThen(req)) {
                    return;
                }
                if (req.data.code == http.SUCCESS) {
                    let data = req.data.data.records;
                    data.forEach(item => {
                        item.created = item.created.substr(0, item.created.length - 3);
                    });
                    this.tableData = data;
                    this.totalNum = req.data.data.total;
                } else {
                    this.showError(req.data.msg);
                }
            }).catch(err => {
                this.tableLoading = false;
                this.httpCatch(err);
            });
        },
        handleSizeChange(pageSize) {
            this.pageSize = pageSize;
            this.loadTable();
        },
        handleCurrentChange(pageNum) {
            this.pageNum = pageNum;
            this.loadTable();
        },

        // 详情弹窗口
        handleDetail(index, row) {
            this.detailDialog = { create: true, params: row };
            this.$nextTick(() => {
                this.detailDialog = { ...this.detailDialog, visible: true };
            });
        },
        handleDetailClosed() {
            this.detailDialog = { create: false, visible: false, params: {} };
        },

        // 添加弹窗口
        handleAdd() {
            this.addDialog = { create: true };
            this.$nextTick(() => {
                this.addDialog = { ...this.addDialog, visible: true };
            });
        },
        handleAddDone() {
            this.addDialog = { visible: false };
            this.loadTable();
        },
        handleAddClosed() {
            this.addDialog = { create: false, visible: false };
        },

        // 编辑弹窗口
        handleEdit(index, row) {
            this.editDialog = { create: true, params: row };
            this.$nextTick(() => {
                this.editDialog = { ...this.editDialog, visible: true };
            });
        },
        handleEditDone() {
            this.editDialog = { create: true, visible: false, params: {} };
            this.loadTable();
        },
        handleEditClosed() {
            this.editDialog = { create: false, visible: false, params: {} };
        },

        handleDisable(status) {
            let text;
            if (status == 1) {
                text = '禁用'
            } else if (status == 0) {
                text = '启用'
            } else {
                this.showError('未知状态值！');
                return;
            }

            if (this.$refs.currentTable.selection.length == 0) {
                this.showError('请选择要'+text+'的信息！');
            } else {
                this.confirm('确认'+text+'所选信息？', function () {
                    let idarr = [];
                    for (let i = 0; i < this.$refs.currentTable.selection.length; i++) {
                        idarr.push(this.$refs.currentTable.selection[i].id);
                    }
                    http.post('v1/role/enable', { ids: idarr, enabled: status }).then(req => {
                        if (this.httpThen(req)) {
                            return;
                        }
                        if (req.data.code == http.SUCCESS) {
                            this.loadTable();
                            this.showSuccess(text+'成功！');
                        } else {
                            this.showError(req.data.msg);
                        }
                    });
                });
            }
        },

        handleDeleteAll() {
            if (this.$refs.currentTable.selection.length == 0) {
                this.showError('请选择要删除的信息！');
            } else {
                this.confirm('确认删除所选信息？', function () {
                    let idarr = [];
                    for (let i = 0; i < this.$refs.currentTable.selection.length; i++) {
                        idarr.push(this.$refs.currentTable.selection[i].id);
                    }
                    http.post('v1/role/del', { ids: idarr }).then(req => {
                        if (this.httpThen(req)) {
                            return;
                        }
                        if (req.data.code == http.SUCCESS) {
                            this.loadTable();
                            this.showSuccess('删除成功！');
                        } else {
                            this.showError(req.data.msg);
                        }
                    });
                });
            }
        },
    }
}
</script>

<style scoped>
</style>
