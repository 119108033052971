<template>
    <div class="dialog-inner" style="padding-top:0;">
        <el-form ref="form" :model="form" :rules="rules" label-width="100px" :show-message="true" class="form-col2">
            <div class="form-group-title" style="margin-top:5px;"><h2>角色信息</h2></div>
            <el-row :gutter="20">
                <el-col :span="24">
                    <el-form-item label="名称" prop="roleName">
                        <el-input type="text" v-model="form.roleName" size="small" maxlength="20" @blur="fieldTrim(form, 'roleName')"></el-input>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row :gutter="20">
                <el-col :span="24">
                    <el-form-item label="权限" prop="rbac">
                        <el-input 
                            type="text" 
                            v-model="filterText" 
                            placeholder="搜索"
                            size="small">
                        </el-input>
                        <div class="selet-tree-panel">
                            <el-tree
                                ref="tree"
                                :data="rbacTree"
                                :props="treeProps"
                                node-key="id"
                                show-checkbox
                                @check="handleCheck"
                                @check-change="handleCheckChange"
                                :filter-node-method="filterNode">
                            </el-tree>
                        </div>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row :gutter="20" class="submit-row">
                <el-col :span="24">
                    <el-form-item>
                        <el-button type="primary" @click="handleSubmit" :loading="submitting" size="small">提交</el-button>
                        <el-button @click="resetForm" size="small">重置</el-button>
                    </el-form-item>
                </el-col>
            </el-row>
        </el-form>
    </div>
</template>

<script>
import http from "@/utils/http"
import common from "@/utils/common"
import verify from "@/utils/verify"

export default {
    name: 'RoleEdit',
    props: {
        params: {
            type: Object,
            default() {
                return {};
            },
        },
    },
    data(){
        return {
            rbacTree: [],
            treeProps: {
                children: 'children',
                label: 'name'
            },
            filterText: '',
            form: {
                roleId: '',
                roleName: '',
                rbac: '',
            },
            rbac: [],
            rules: {
                roleName: [{required: true, message: '名称不能为空', trigger: 'blur'}],
            },
            submitting: false
        }
    },
    created() {
        let {id, name, rbac} = this.params;
        this.form = { roleId: id, roleName: name, rbac };
        if (rbac) {
            this.rbac = rbac.replace(/^,|,$/g, '').split(',');
        }

        this.$nextTick(() => {
            this.loadRbacList();
        });
    },
    watch: {
        filterText(val) {
            this.$refs.tree.filter(val);
        },
        ['rbacTree'] () {
            this.$nextTick(() => {
                this.setCheckeds(this.rbac);
            });
        },
        ['rbac']: {
            immediate: true,
            handler: function(val) {
                if (val.length == 0) {
                    this.form.rbac = '';
                } else {
                    this.form.rbac = ',' + val.join(',') + ',';
                }
                this.$nextTick(() => {
                    this.setCheckeds(val);
                });
            }
        }
    },
    methods: {
        ...common,
        ...verify,
        filterNode(value, data) {
            if (!value) return true;
            return data[this.treeProps.label].indexOf(value) !== -1;
        },
        loadRbacList() {
            let loading = this.load();
            http.get('v1/role/rolePermissionTree').then(req => {
                loading.close();
                if (req.data.code == http.SUCCESS) {
                    this.rbacTree = req.data.data;
                } else {
                    this.showError(req.data.msg);
                }
            }).catch(err => {
                loading.close();
                this.httpCatch(err);
            });
        },
        handleSubmit(){
            this.$refs.form.validate((valid) => {
                if (valid) {
                    this.submitting = true;
                    http.post('v1/role/edit', this.form).then(req => {
                        this.submitting = false;
                        if (req.data.code == http.SUCCESS) {
                            this.showSuccess('修改成功', () => {
                                this.$emit('done');
                            });
                        } else {
                            this.showError(req.data.msg);
                        }
                    }).catch(err => {
                        this.tableLoading = false;
                        this.httpCatch(err);
                    });
                } else {
                    return false;
                }
            });
        },
        handleCheck(data) {
            if (data.level == 2) {
                this.$refs.tree.setChecked(data.pid, true);
            }
        },
        handleCheckChange() {
            this.$nextTick(() => {
                let nodes = this.$refs.tree.getCheckedNodes(false, true);
                let arr = [];
                for (let i = 0; i < nodes.length; i++) {
                    arr.push(nodes[i].id);
                }
                this.rbac = arr;
            });
        },
        setCheckeds(idArr, isCheckChange = false) {
            this.clearCheckeds();
            for (let i = 0; i < idArr.length; i++) {
                this.$refs.tree.setChecked(idArr[i], true);
            }
            if (isCheckChange) {
                this.handleCheckChange();
            }
        },
        clearCheckeds(isCheckChange = false) {
            let nodes = this.$refs.tree.getCheckedNodes(false, true);
            for (let i = 0; i < nodes.length; i++) {
                this.$refs.tree.setChecked(nodes[i].id, false);
            }
            if (isCheckChange) {
                this.handleCheckChange();
            }
        }
    }
};
</script>

<style scoped>
</style>